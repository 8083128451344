.root {
	position: relative;
	display: flex;
	gap: 20px;

	& * {
		line-height: 1;
	}
}

.link {
	width: 100%;
	position: relative;
	padding: 10px;
	display: flex;
	gap: 20px;
	text-decoration: none;
	color: inherit;
	border-radius: 8px;
	border: 1px solid transparent;
	transition: var(--transition-standard);

	@media (hover: hover) {
		&:hover {
			border: 1px solid var(--primary-dark-blue-005);
			background-color: var(--primary-dark-blue-005);
		}
	}
}

.details {
	width: 100%;
	padding-top: 10px;
	padding-right: 10px;
	display: flex;
	flex-direction: column;

	@media (--md) {
		padding-top: 15px;
	}
}

.name {
	@extend %typo-utility-4;

	@media (--md) {
		@extend %typo-utility-2;
	}
}

.variant {
	opacity: 0.5;

	@extend %typo-utility-5;

	@media (--md) {
		@extend %typo-utility-3;
	}
}

.size {
	@extend %typo-utility-5;

	@media (--md) {
		@extend %typo-utility-3;
	}
}

.imageContainer {
	height: 99px;
	aspect-ratio: 4 / 5;
	position: relative;

	@media (--md) {
		height: 150px;
	}
}

.image {
	aspect-ratio: 4 / 5;
	object-fit: cover;
	background-color: var(--primary-greige);
	border-radius: 4px;
}

.description {
	display: flex;
	flex-direction: column;
	gap: calc(10px * 0.8);

	@media (--md) {
		gap: calc(15px * 0.8);
	}
}

.price {
	@extend %typo-utility-4;

	@media (--md) {
		@extend %typo-utility-2;
	}
}

.controls {
	margin-top: var(--space-xs);
	margin-bottom: calc(16px * 0.8);
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (--md) {
		margin-top: var(--space-md);
		margin-bottom: 21px;
	}
}

.counter {
	position: absolute;
	bottom: 10px;
	right: 10px;
	z-index: var(--z-above);
}

.remove {
	--size: 24px;

	width: var(--size);
	height: var(--size);
	position: absolute;
	top: 5px;
	right: 15px;
	z-index: var(--z-above);

	@media (--md) {
		--size: 32px;

		top: 10px;
		right: 10px;
	}
}

.priceContainer {
	display: flex;
	flex-direction: column;
	gap: var(--space-2xs);

	@media (--md) {
		gap: var(--space-xs);
	}

	&.inCheckout {
		flex-direction: row;
	}
}

.priceBeforeDiscount {
	color: var(--primary-dark-blue-04);
	text-decoration: line-through;

	@extend %typo-utility-6;

	@media (--md) {
		@extend %typo-utility-4;
	}

	&.inCheckout {
		@extend %typo-utility-4;

		@media (--md) {
			@extend %typo-utility-2;
		}
	}
}
